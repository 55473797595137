<template>
  <CalculatorApp />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CalculatorApp from './components/CalculatorApp.vue'

export default defineComponent({
  name: 'App',
  components: {
    CalculatorApp
  }
});
</script>

<style>
</style>
