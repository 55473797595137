<template>
  <p>
    <label>
      Gewünschter Brennwert (kcal):
      <input v-model.number="targetedKilocalories" type="number" min="0">
    </label>
  </p>
  <p>
    <input type="checkbox" id="checkbox" v-model="withManualWeightCorrection">
    <label for="checkbox">manu. Gewichtskorrektur</label>
  </p>
  <p v-if="withManualWeightCorrection">
    <label>
      Korrektur (g):
      <input v-if="!isAndroid" v-model.number="manualWeightCorrection" type="number">
      <input v-else v-model.number="manualWeightCorrection" inputmode="text">
    </label>
  </p>
  <p>Gesamtmasse: {{ overallAmount.toFixed(0) }}g</p>
  <p>Berechn. Portion: {{ calculatedWeight.toFixed(0) }}g</p>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { PropType } from 'vue';
import { Ingredient } from "@/models/ingredient";

export default defineComponent({
  name: 'CalcWeight',
  props: {
    ingredients: {
      type: Array as PropType<Ingredient[]>,
      required: true
    }
  },
  data() {
    const weightCorrection = localStorage.getItem("manualWeightCorrection");

    return {
      withManualWeightCorrection: false,
      manualWeightCorrection: weightCorrection === null ? 0.0 : JSON.parse(weightCorrection) ,
      targetedKilocalories: 0.0,
    }
  },
  methods: {
    updateTargetedKilocalories(event: Event) {
      this.targetedKilocalories = Number.parseFloat((event.target as HTMLInputElement).value);
      this.$emit("targeted-kilocalories-input", this.targetedKilocalories);
    }
  },
  computed: {
      overallAmount(): number {
      // FIXME: Duplicate definition in CalculatorApp.vue
      return this.ingredients.reduce((accu, ingredient) => accu + ingredient.amountInGrams, 0.0);
    },
    calculatedWeight(): number {
      let res = 0.0
      if (this.ingredients.length > 0) {
        let caloriesOfIngredients = this.ingredients.map(
          ingredient => (ingredient.caloriesPerHundredGrams/100.0) * ingredient.amountInGrams
        )
        let overallKilocalories = caloriesOfIngredients.reduce((accu, value) => accu+value, 0.0)
        let manualWeightCorrection = this.withManualWeightCorrection ? this.manualWeightCorrection : 0.0;
	localStorage.setItem("manualWeightCorrection", JSON.stringify(manualWeightCorrection));
        this.$emit("weight-correction", manualWeightCorrection);
        res = (this.targetedKilocalories / overallKilocalories) * (this.overallAmount+manualWeightCorrection)
      }
      this.$emit("calculated-weight", res);
      return res
    },
    isAndroid() {
      return window.navigator.userAgent.includes("Android");
    }
  }
})
</script>
