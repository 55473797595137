<template>
  <h2>Zutaten</h2>
  <form @submit.prevent="newIngredientEvent">
    <p>
      <label>
        Name:
        <input type="text" v-model.trim="newIngredient.name" id="new-ingredient-name" v-bind:placeholder="'Zutat ' + (ingredients.length+1)">
      </label>
    </p>
    <p>
      <label>
        Brennwert (kcal/100g):
        <input type="number" min="0" v-model.number="newIngredient.caloriesPerHundredGrams">
      </label>
    </p>
    <p>
      <label>
        Menge (g):
        <input type="number" min="1" v-model.number="newIngredient.amountInGrams">
      </label>
    </p>
    <button>Hinzuf.</button>
  </form>
  <ul v-if="ingredients.length > 0">
    <li v-for="(ingredient, index) in ingredients" :key="ingredient.name">
      {{ingredient.name}}, {{ingredient.caloriesPerHundredGrams}}kcal/100g, {{ingredient.amountInGrams}}g <button @click="$emit('removeIngredient', index)">Entfernen</button>
    </li>
  </ul>
  <p v-else><em>Keine Zutaten</em></p>
</template>
<script lang="ts">

import {defineComponent} from "vue";
import {PropType} from "vue";
import {Ingredient} from "@/models/ingredient";

export default defineComponent({
  name: 'IngredientList',
  emits: {
    newIngredient({ name, amountInGrams, caloriesPerHundredGrams }: Ingredient) {
      if (name && amountInGrams >= 0 && caloriesPerHundredGrams >= 0) {
        return true
      } else {
        console.warn('Invalid submit event payload!')
        return false
      }
    },
    removeIngredient: (/*_index: number*/) => true
  },
  props: {
    ingredients: {
      type: Array as PropType<Ingredient[]>,
      required: true
    }
  },
  data() {
    return {
      newIngredient: new Ingredient()
    }
  },
  methods: {
    newIngredientEvent() {
      let payload = this.newIngredient
      if (payload.name === "") {
        payload.name = `Zutat ` + (this.ingredients.length+1)
      }
      this.$emit('newIngredient', payload)
      this.newIngredient = new Ingredient()
      document.getElementById("new-ingredient-name")!.focus()
    }
  }
})
</script>
