import { Ingredient } from "@/models/ingredient";

export default class ServingResult extends HTMLElement {
    static observedAttributes = ["data-ingredients"];

	wrapper = document.createElement("div");

    constructor() {
        super();
	
        this.wrapper.innerHTML = `
            <h2>Portion</h2>
            <table></table>
	`
    }

    getIngredients() : Ingredient[] {
        const serialized = this.getAttribute("data-ingredients");
        if (!serialized) {
            return [];
        }
        return JSON.parse(serialized);
    }

    connectedCallback() {
        const shadow = this.attachShadow({ mode: "open" });

	shadow.appendChild(this.wrapper);
	this.updateRows(this.wrapper);
    }

    updateRows(elem: HTMLElement) {
        const table = elem.getElementsByTagName("table")[0];
	table.replaceChildren();
        for (const ingredient of this.getIngredients()) {
            const row = document.createElement("tr");

            const name = document.createElement("td");
            name.textContent = ingredient.name;

            const amount = document.createElement("td");
            amount.textContent = `${ingredient.amountInGrams.toLocaleString(undefined, {maximumFractionDigits: 1})}g`;

            row.appendChild(name);
            row.appendChild(amount);

            table.appendChild(row);
        }
    }
    
    attributeChangedCallback(name: String, oldValue: String /*, newValue: String*/) {
        if (oldValue === null) {
            // This is just the parser. There is no table yet to update.
            return;
	}
        if (name === "data-ingredients") {
            this.updateRows(this.wrapper);
	}
    }
}
