import { createApp } from 'vue'
import App from './App.vue'
import { registerServiceWorker } from './registerServiceWorker'
import ServingResult from './components/ServingResult'

registerServiceWorker();

createApp(App).mount('#app')

customElements.define("serving-result", ServingResult)
