export class Ingredient {
  name: string;
  amountInGrams: number;
  caloriesPerHundredGrams: number;

  constructor(obj?: any) {
    this.name = obj && obj.name || ""
    this.amountInGrams = obj && obj.amountInGrams || 1.0
    this.caloriesPerHundredGrams = obj && obj.caloriesPerHundredGrams || 0.0
  }
}
