<template>
  <h1>kcal: <em>der</em> Kalorien-Calculator</h1>
  <CalcWeight
    @weight-correction="val => weightCorrection = val"
    @calculated-weight="val => calculatedWeight = val"
    :ingredients="ingredients"/>
  <IngredientList
    @new-ingredient="addIngredient"
    @remove-ingredient="removeIngredient"
    :ingredients="ingredients"/>
  <serving-result :data-ingredients="JSON.stringify(servingIngredients)"/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import IngredientList from "@/components/IngredientList.vue";
import CalcWeight from "@/components/CalcWeight.vue";
import {Ingredient} from "@/models/ingredient";

interface Data {
  ingredients: Ingredient[],
  weightCorrection: number,
  calculatedWeight: number,
}

export default defineComponent({
  name: "CalculatorApp",
  components: {CalcWeight, IngredientList},
  methods: {
    addIngredient(newIngredient: Ingredient) {
      this.ingredients.push(newIngredient);
      localStorage.setItem("ingredients", JSON.stringify(this.ingredients));
    },
    removeIngredient(index: number) {
      this.ingredients.splice(index, 1);
      localStorage.setItem("ingredients", JSON.stringify(this.ingredients));
    }
  },
  data(): Data {
    const ingredientsJson = localStorage.getItem("ingredients");
    
    return {
      ingredients: ingredientsJson === null ? [] : JSON.parse(ingredientsJson),
      weightCorrection: 0.0,
      calculatedWeight: 0.0,
    }
  },
  computed: {
      servingIngredients() {
      const totalAmount = this.ingredients.reduce((accu, ingredient) => accu + ingredient.amountInGrams, 0.0);

      const ratio = this.calculatedWeight / (totalAmount + this.weightCorrection);
      return this.ingredients.map(ingredient =>
        new Ingredient({
          name: ingredient.name,
          amountInGrams: ingredient.amountInGrams * ratio,
          caloriesPerHundredGrams: ingredient.caloriesPerHundredGrams}
        ))
    }
  }
})
</script>
